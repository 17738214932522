import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
  
    // Access the parameter values
    const pageId = params.get('pageId');
    const [message, setMessage] = useState("");
    useEffect(() => {
        (async () => {
            try {
                // The custom message set in the settings
                const pageSettings = await donations.get(`page/info?id=${pageId}`); // API
                setMessage(pageSettings.data.donation_success_msg);
            } catch (error) {
                console.warn(error);
            }
        })()
    }, []);


    return (
        <div>
            {/* Display the custom message */}
            <h1 className='mt-3 text-center'>{message}</h1>
        </div>
    );
}

export default Success;
