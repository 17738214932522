import React from "react";
import "./Table.css";
import "../MainCss/Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';


function TablePreset(props) {

    const tableInstance = props.data[0].tableInstance;
    const selectedIds = props.data[0].selectedIds;
    const pagination = props.data[0].pagination;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    let location = useLocation().pathname.split('/')[2];
    
    return (
        <>
        <div className="table-container">
            <table className={"dataTable"} {...getTableProps()}>
                <thead className="customThead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th className="px-4 py-3" {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                    </tr>
                ))}
                </thead>
                <tbody className="customTbody" {...getTableBodyProps()}>
                {rows.map(row => {
                const firstKey = Object.keys(row.original)[0];
                prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} 
                            style={{borderTop: "1px solid lightgray"}}
                            >
                            {row.cells.map((cell, index) => (
                                <td
                                onClick={
                                index === 0 ? (e => 
                                {const isSelected = selectedIds.includes(row.original[firstKey])
                                    if (isSelected) {
                                    props.handleSelection(row.original[firstKey], false)
                                    } else {
                                    props.handleSelection(row.original[firstKey], true)
                                    }
                                }) : null}
                                className="px-4 py-2" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
        </div>
        
        <div className={`${location === 'consument' || location === 'bedrijf' ? 'widthPaginationConsumers' : 'widthPagination'} d-flex justify-content-between ps-3 py-1`}>
        <div className="d-flex gap-0 ">
        <select value={pagination.page_size} onChange={e => props.setPagination({...pagination, 'page_size': e.target.value, 'page': 1})} className="form-select border-0 pt-1 page-sizer">
            {/* <option value={1}>1</option> */}
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
        </select>
        <div className="page-display text-nowrap">
            {pagination.begin_of_page}-{pagination.end_of_page} van {pagination.max_items}
        </div>
        </div>
        <div className=" d-flex gap-1">
        <button 
            onClick={e => props.setPagination( {...pagination, "page":  1 })} 
            disabled={pagination.page == 1 ? true : false} 
            type="button" 
            className="btn rounded-2 border-0 bg-white navigationButtons"
            >
            <FontAwesomeIcon icon={faChevronLeft}/>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <button 
            onClick={e => props.setPagination( {...pagination, "page":  pagination.page - 1}) } 
            disabled={pagination.page <= 1 ? true : false} 
            type="button" 
            className="btn rounded-2 border-0 bg-white bg-white navigationButtons"
            >
            <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <button
        onClick={e => props.setPagination( {...pagination, "page":  pagination.page - 1}) } 
        hidden={pagination.page <= 1 ? true : false} 
        type="button" 
        className="btn rounded-2 border-1 bg-white navigationButtons"
        style={{fontWeight: "600", color: "black"}}
        >{pagination.page - 1}
        </button>
        <button
            
        type="button" 
        className="btn rounded-2 border-0 bg-white navigationButtons"
        disabled
        style={{fontWeight: "600", color: "black", cursor: "default"}}
        >
            {pagination.page}
        </button>
        <button
        onClick={e => props.setPagination( {...pagination, "page":  pagination.page + 1}) } 
        hidden={(Math.ceil(pagination.max_items / pagination.page_size)) <= pagination.page ? true : false} 
        type="button" 
        className="btn rounded-2 border-1 bg-white navigationButtons"
        style={{fontWeight: "600", color: "black"}}
        >
            {pagination.page + 1}
        </button>
        <button
        onClick={e => props.setPagination( {...pagination, "page":  pagination.page + 1}) } 
        disabled={((Math.ceil(pagination.max_items / pagination.page_size)) == pagination.page || (pagination.max_items <= 0 ) ? true : false)} 
        type="button" 
        className="btn rounded-2 border-0 bg-white navigationButtons"
        >
            <FontAwesomeIcon icon={faChevronRight}/>
        </button>
        <button
        onClick={e => (Math.ceil(pagination.max_items / pagination.page_size)) == 0 ? props.setPagination({...pagination, "page": 1}) : props.setPagination({...pagination, "page":  Math.ceil(pagination.max_items / pagination.page_size)})} 
        disabled={((Math.ceil(pagination.max_items / pagination.page_size)) == pagination.page || (pagination.max_items <= 0 ) ? true : false)}
        type="button" 
        className="btn rounded-2 border-0 bg-white navigationButtons"
        >
            <FontAwesomeIcon icon={faChevronRight}/>
            <FontAwesomeIcon icon={faChevronRight}/>
        </button>
        </div>
    </div>

    
    </>
    );
}

export default TablePreset;