import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Failed = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
  
    // Access the parameter values
    const pageId = params.get('pageId');
    
    return (
        <div>
            <h1 className='mt-3 text-center'>Transactie is niet voltooid</h1>
            <h5 className='text-center'>Ga <a href={'/doneer/' + pageId}>terug</a></h5>
        </div>
    );
}

export default Failed;
