import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import Cookies from 'universal-cookie';
import SideBar from '../SideBar/SideBar';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';
import { useParams, useNavigate, redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import './EditDebit.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditDebit = () => {
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [prevIsLast, setPrevIsLast] = useState(false);
  const [debit, setDebit] = useState([]);
  const [prevDebit, setPrevDebit] = useState([]); // The value the "last debit" had before going into edit mode so we can set it back when the user cancels the edit
  const { mandateId } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();

  // This is so we can correctly display time using dayjs
  dayjs.extend(relativeTime);
  dayjs.locale('nl');

  useEffect(() => {
      (async () => {
          try {
              const incassoResponse = await donations.get(`debit?mandateId=${mandateId}`); // Gets the incasso's from the api
              setDebit(incassoResponse.data[0]);
              setIsLast(incassoResponse.data.last === 0 ? false : true)
              setLoading(false);
          } catch (error) {
              console.warn(error);
              if (error.response.status === 401) {
                  cookies.remove('token', {path: '/'});
                  navigate('/');
              }
          }
      })()
  }, []);

  // Validate the iban based on number and character setup
  function validateIBAN(ibanString) {
    // Test if first or second character isn't a letter
    if (!/^[a-zA-Z]{2}/.test(ibanString)) {
        return false;
    }
    
    // Test if third or fourth character isn't a number
    if (!/^\d+$/.test(ibanString.substring(2, 4))) {
        return false;
    }
    
    // Test if fifth to eighth characters aren't letters
    if (!/^[a-zA-Z]{4}/.test(ibanString.substring(4, 8))) {
        return false;
    }
    
    // Test if ninth to eighteenth characters aren't numbers
    if (!/^\d+$/.test(ibanString.substring(8, 18))) {
        return false;
    }

    // Test if the iban isn't longer than 18 or shorter than 18
    if (ibanString.length < 18 || ibanString.length > 18) {
        return false;
    };

    return true;
  }

  // update the information in the debit value array
  const handleInputChange = (event, key) => {
    setDebit({...debit, [key]: event.target.value})
  }

  // check if all the values that are required are filled in
  const validateFields = () => {
    let everythingFilledIn = true;
    const requiredInputs = document.getElementsByClassName('required');
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].value == "") { // If a field is empty notify people with a toast
        toast.error('Vul alle velden in', { //Toast to notify the user
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return false;
      }
    }

    // If the iban is incorrect notify the user with a toast
    if (!validateIBAN(document.getElementById('iban').value)) {
      toast.error('Vul een geldige IBAN in', { //Toast to notify the user
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return false;
    }

    return true;
  }

  // Submit the changes and save them in the database
  const submitChanges = async (event) => {
    event.preventDefault(); //Prevent the form from reloading the page
    if (!validateFields()) { // If not every field is correctly filled in, prevents the code from running any further
      return;
    }
    const updateDebitResponse = await donations.put(`debit?mandateId=${mandateId}&amount=${debit.amount}&description=${debit.description}&interval_value=${debit.interval_value}&interval_period=${debit.interval_period}&process_date=${debit.process_date}&last=${isLast == true ? 1 : 0}`); // Update the debit information
    const updateMandateResponse = await donations.put(`mandate?mandateId=${mandateId}&owner=${debit.owner}&iban=${debit.iban}`); // Update the mandate information

    // If both requests were handled succesfully, give the user a pop-up telling them they were saved succesfully
    if (updateDebitResponse.data.status == 100 || updateMandateResponse.data.status == 100) {
      setPrevDebit(debit)
      setPrevIsLast(isLast);
      setEditing(false);
      toast.success('Incasso opgeslagen!', { //Toast to notify the user
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error('Er ging iets fout, probeer het later nog eens', { //Toast to notify the user
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }

  // Cancels the edit mode and makes every input disabled
  function cancelEdit() {
    setEditing(false);
    setDebit(prevDebit);
    setIsLast(prevIsLast);
  }

  // Starts editing the input fields and makes them all enabled
  function startEdit() {
    setEditing(true);
    setPrevIsLast(isLast);
    setPrevDebit(debit);
  }

  return (
    <div className='d-flex position-absolute'>
        <SideBar/>
        <div className='mainContainer p-3'>
          <div className='p-3 col-sm-10 col-lg-7 col-xl-6 col-md-9 col-12'>
              <button className='btn btn-light' onClick={(e) => navigate('/debits')}>Terug</button>
              <h1>{mandateId}</h1>
              <form onSubmit={submitChanges}>
                <div className='debitSettingBox mb-4'>
                  <h3>Bankrekening</h3>
                  {/* Rekening Eigenaar */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name'>Rekening houder
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input id='owner' className='required' value={debit.owner} onChange={(e) => handleInputChange(e, 'owner')} disabled={!editing} type='text'/>
                  </div>
                  {/* Rekening Houder */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name'>IBAN
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input id='iban' className='required' value={debit.iban} onChange={(e) => handleInputChange(e, 'iban')} disabled={!editing} type='text'/>
                  </div>
                </div>
                <div className='debitSettingBox mb-4'>
                  <h3>Incasso</h3>
                  {/* Bedrag */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name'>Bedrag
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input min={0.01} className='required numberInputStyle' id='amount' step='any' value={debit.amount} onChange={(e) => handleInputChange(e, 'amount')} disabled={!editing} type='number'/>
                  </div>
                  {/* Rekening Houder */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name'>beschrijving
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input id='description' className='required' value={debit.description} onChange={(e) => handleInputChange(e, 'description')} disabled={!editing} type='text'/>
                  </div>
                  {/* Interval */}
                  <div className='debitSettingInputBox input-group d-flex m-2'>
                      <span id='name'>Herhaling<br/>
                          <span className='incassoError'>Deze waarde mag niet 0 of lager dan 0 zijn</span>
                      </span>
                      <div className='d-flex align-items-center'>
                        <input id='interval_value' disabled={!editing} onChange={(e) => handleInputChange(e, 'interval_value')} type='number' className='numberInputStyle required' value={debit.interval_value}/>
                        <select id='intervalPeriod' disabled={!editing} onChange={(e) => handleInputChange(e, 'interval_period')} value={debit.interval_period} className="required" >
                            <option value="1">{debit.interval_value > 1 ? 'Dagen' : 'Dag'}</option>
                            <option value="2">{debit.interval_value > 1 ? 'Weken' : 'Week'}</option>
                            <option value="3">{debit.interval_value > 1 ? 'Maanden' : 'Maand'}</option>
                            <option value="4">{debit.interval_value > 1 ? 'Kwartalen' : 'Kwartaal'}</option>
                            <option value="5">Half jaar</option>
                            <option value="6">Jaar</option>
                        </select>
                      </div>
                  </div>
                  {/* Datum */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name'>Volgende Afschrijving
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input id='process_date' className='required' value={debit.process_date} onChange={(e) => handleInputChange(e, 'process_date')} disabled={!editing} type='date'/>
                  </div>
                  {/* Laatste transactie */}
                  <div className='debitSettingInputBox d-flex m-2'>
                      <span id='name' className='me-3'>Laatste Afschrijving
                          <span title='Zodra deze incasso wordt uitgevoerd en het de laatste is, wordt deze hierna niet meer uitgevoerd.'> <FontAwesomeIcon className='statusIcon questionCircle' icon={faQuestionCircle}/></span>
                          <br/>
                          <span className='incassoError'>Fout</span>
                      </span>
                      <input id='last' className='required' checked={isLast} onChange={(e) => setIsLast(e.target.checked)} disabled={!editing} type='checkbox'/>
                  </div>
                </div>
                {editing ? 
                  <div className='debitButtonContainer'>
                    <button type='submit' className='debit-button save-debit'>Sla op</button>
                    <button className='debit-button cancel-debit' onClick={cancelEdit}>Annuleren</button>
                  </div>
                  :
                  <button className='debit-button edit-debit' onClick={startEdit}>Bewerk</button>
                }
              </form>
          </div>
        </div>
        <ToastContainer/>
    </div>
  );
}

export default EditDebit;
