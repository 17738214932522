import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/nl';
import relativeTime from 'dayjs/plugin/relativeTime';
import CountUp from 'react-countup';
import donations from '../Api/Donations';
import './Analytics.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../SideBar/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { func } from 'prop-types';

const Analytics = () => {
    const today = dayjs();
    dayjs.extend(weekday)

    const navigate = useNavigate();
    const cookies = new Cookies();
    const [customDate, setCustomDate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [analytics, setAnalytics] = useState([]); // Analytics from the API
    const [selectedButton, setSelectedButton] = useState('Vandaag'); // The button that's currently selected

    // Values from the previous analytics, this way we can display the new value counting from the previous values
    const [previousValues, setPreviousValues] = useState({
        donationCount: 0,
        avgDonationAmount: 0,
        totalDonationAmount: 0,  
        newDebits: 0,
        processedDebits: 0,
        totalDebitAmount: 0,
    })

    // Period of what stats should be displayed
    const [dates, setDates] = useState({startDate: today, endDate: today})

    // When the users presses a button to change the period
    function dateChange(start, end, btnName) {
        setSelectedButton(btnName);
        setCustomDate(false);
        setDates({startDate: start, endDate: end});   
    }

    // Inputs a custom date
    function handleCustomDateChange(e) {
        setDates({...dates, [e.target.id]: dayjs(e.target.value)});
    }

    // Click on the custom date button
    function customDateClicked() {
        setCustomDate(true);
        setSelectedButton('custom');
    }

    //So we can correctly display and format the time using dayjs
    dayjs.extend(weekday)
    dayjs.extend(relativeTime);
    dayjs.locale('nl');

    //Get the mandate information
    useEffect(() => {
        (async () => {
            try {
                // If the previous analytics weren't empty it sets the previous analytics so we can smoothly transition to the new values
                if (analytics.length !== 0) {
                    setPreviousValues(analytics);
                }

                // Get the analytics from the API
                const transactionsResponse = await donations.get(`transactions/statistics?startDate=${dates.startDate.format('YYYY-MM-DD')}&endDate=${dates.endDate.format('YYYY-MM-DD')}`); // Gets the order
                setAnalytics(transactionsResponse.data);
                setLoading(false);
            } catch (error) {
                console.warn(error);
                if (error.response.status === 401) {
                    cookies.remove('token', {path: '/'});
                    navigate('/');
                }
            }
        })()
    }, [dates]);

    return (
        <>
            {loading ?
            <div className='d-flex justify-content-center mt-4'>
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <div className='d-flex position-absolute'>
            <SideBar/>
                <div className='p-3 mainContainer'>
                    <h3>Home</h3>
                    {/* Display the start and end date */}
                    <h5>Van: {dates.startDate.locale('nl').format('dddd D MMMM (DD-MM-YYYY)').replace(/(^[a-z]| [a-z])/g, function(m) { return m.toUpperCase(); })}</h5>
                    <h5>Tot: {dates.endDate.locale('nl').format('dddd D MMMM (DD-MM-YYYY)').replace(/(^[a-z]| [a-z])/g, function(m) { return m.toUpperCase(); })}</h5>
                    <div className='mainAnalyticsContainer'>
                        {/* All date options */}
                        <div className='dateSideBarContainer gap-2'>
                            <button className='analyticsButton date-button' id={selectedButton == 'today' ? 'selectedAnalytics' : ''} onClick={() => dateChange(today, today, 'today')}>Vandaag</button>
                            <button className='analyticsButton date-button' id={selectedButton == 'week' ? 'selectedAnalytics' : ''} onClick={() => dateChange(today.weekday(0), today.weekday(6), 'week')}>Deze week</button>
                            <button className='analyticsButton date-button' id={selectedButton == 'month' ? 'selectedAnalytics' : ''} onClick={() => dateChange(today.startOf('month'), today.endOf('month'), 'month')}>Deze maand</button>
                            <button className='analyticsButton date-button' id={selectedButton == 'year' ? 'selectedAnalytics' : ''} onClick={() => dateChange(today.startOf('year'), today.endOf('year'), 'year')}>Dit jaar</button>
                            <button className='analyticsButton date-button' id={selectedButton =='custom' ? 'selectedAnalytics' : ''} onClick={customDateClicked}>Aangepast</button>
                        </div>

                        {/* Analytics */}
                        <div className='anaylyticsContainer'>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.donationCount} end={analytics.donationCount} separator="." duration={2}/></span>
                                <span className='analyticTitle'>Donaties</span>
                            </div>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.avgDonationAmount} end={analytics.avgDonationAmount} prefix='€ '  decimal=',' decimals={2} separator="." duration={2}/></span>
                                <span className='analyticTitle'>Gemiddelde donatie</span>
                            </div>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.totalDonationAmount} end={analytics.totalDonationAmount} prefix='€ '  decimal=',' decimals={2} separator="." duration={2}/></span>
                                <span className='analyticTitle'>Omzet donaties</span>
                            </div>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.newDebits} end={analytics.newDebits} separator="." duration={2}/></span>
                                    <span className='analyticTitle'>Nieuwe incasso's</span>
                                </div>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.processedDebits} end={analytics.processedDebits} separator="." duration={2}/></span>
                                    <span className='analyticTitle'>Incasso's uitgevoerd</span>
                                </div>
                            <div className='col-10 col-md-5 col-lg-3 analyticContainer shadow h-60 rounded-3'>
                                <span className='analyticAmount'><CountUp start={previousValues.totalDebitAmount} end={analytics.totalDebitAmount} prefix='€ '  decimal=',' decimals={2} separator="." duration={2}/></span>
                                <span className='analyticTitle'>Omzet incasso's</span>
                            </div>
                        </div>
                    </div>
                    {/* When custom date is selected display the custom inputs */}
                    {customDate ?
                    <div className='col-2'>
                        <label htmlFor="startDate">Start datum</label>
                        <input id="startDate" onChange={handleCustomDateChange} value={dates.startDate.format('YYYY-MM-DD')} className="mb-2 form-control" type="date" />
                        <label htmlFor="endDate">Eind datum</label>
                        <input id="endDate" onChange={handleCustomDateChange} value={dates.endDate.format('YYYY-MM-DD')} className="form-control" type="date" />
                    </div> : <></>}
                </div>
            </div>}
        </>
    );
}

export default Analytics;