import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import Cookies from 'universal-cookie';
import './Settings.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../SideBar/SideBar';

const PagesAdd = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    // The settings information gathered from the API call
    const [pageInfo, setPageInfo] = useState({
        page_title: "",
        donation_title: "",
        donation_description: "",
        donation_success_msg: "",
        type_color: "#ffffff",
        selection_color: "#ffffff",
        donate_color: "#ffffff",
        fee: 0.00,
    });

    // Input change of the setting inputs
    const handleInputChange = (event, key) => {
        setPageInfo({...pageInfo, [key]: event.target.value})
    }

    // Save the changes
    const submitChanges = async () => {
        setSubmitting(true);
        try {
            console.log(pageInfo)
            if (pageInfo.page_title &&
                pageInfo.donation_title &&
                pageInfo.type_color &&
                pageInfo.selection_color &&
                pageInfo.donate_color &&
                pageInfo.donation_description &&
                pageInfo.donation_success_msg
                )
            {
                const settingsResponse = await donations.post(`pages`, pageInfo); // Update the settings with the API
                console.log(settingsResponse.data);
                // When the status isn't 100 notify the user that something went wrong
                if (settingsResponse.data.status != 100) {
                    toast.error('Er ging iets fout, probeer het later nog eens', { //Toast to notify the user
                        position: "top-center",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                } else {
                    toast.success('Instellingen opgeslagen!', { //Toast to notify the user
                        position: "top-center",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                };
            } else {
                toast.error('Vul elk veld in', { //Toast to notify the user
                    position: "top-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }

        } catch (error) {
            console.warn(error);
            if (error.response.status === 401) {
                cookies.remove('token', {path: '/'});
                navigate('/');
            }
        }
        setSubmitting(false);
    }

    return (
        <div className='d-flex position-absolute'>
            <SideBar/>
            <div className='mainContainer'>
                <div className='p-3 col-sm-10 col-lg-7 col-xl-6 col-md-9 col-12'>
                    <h1>Instellingen</h1>
                    <div className='settingGroup'>
                        <h3>Pagina</h3>
                        <div className='inputContainer'>
                            <span id='name'>Pagina Titel </span><input type='text' value={pageInfo.page_title} onChange={(e) => handleInputChange(e, "page_title")}/>
                        </div>
                    </div>

                    <div className='settingGroup'>
                        <h3>Donatie pagina</h3>
                        <div className='inputContainer'>
                            <span id='name'>Titel </span><input type='text' value={pageInfo.donation_title} onChange={(e) => handleInputChange(e, "donation_title")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Transactie kosten </span><input value={pageInfo.fee} type='number' onChange={(e) => handleInputChange(e, "fee")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Balk selecteer kleur </span><input type='color' value={pageInfo.type_color} onChange={(e) => handleInputChange(e, "type_color")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Bedrag kleur </span><input type='color' value={pageInfo.selection_color} onChange={(e) => handleInputChange(e, "selection_color")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Doneer knop kleur  </span><input type='color' value={pageInfo.donate_color} onChange={(e) => handleInputChange(e, "donate_color")}/>
                        </div>
                        <br/>
                    </div>

                    <div className='settingGroup'>
                        <h3>Overig</h3>
                        <div className='inputContainer'>
                            <span id='name'>Donatie beschrijving </span><input type='text' value={pageInfo.donation_description} onChange={(e) => handleInputChange(e, "donation_description")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Betaling Geslaagd Tekst </span><input type='text' value={pageInfo.donation_success_msg} onChange={(e) => handleInputChange(e, "donation_success_msg")}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='save-button' onClick={submitChanges}> {submitting ?
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : ''} Voeg Toe</button>
                        </div>
                    </div>
                    
                    <button className='back-button' onClick={(e) => navigate(`/pages`)}>Terug</button>
                </div>
                <ToastContainer/>
            </div>
        </div>
    );
}

export default PagesAdd;