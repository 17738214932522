import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Success from "./components/Donation/Donation-success";
import MissingInfo from "./components/Donation/Missing-Info";
import NotFound from "./components/NotFound/NotFound";
import Failed from "./components/Donation/Donation-failed";
import PageSettings from "./components/Settings/PageSettings";
import DonationPage from "./components/Donation/DonatePage";
import TransactionsPage from "./components/Transactions/Transactions";
import Debits from "./components/Debits/Debits";
import Analytics from "./components/Analytics/Analytics";
import EditDebit from "./components/Debits/EditDebit";
import Pages from "./components/Settings/Pages";
import PagesAdd from "./components/Settings/PagesAdd";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/doneer/:pageId" element={<DonationPage />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
        <Route path="/donation/info-missing" element={<MissingInfo />}></Route>
        <Route path="/donation/success" element={<Success />}></Route>
        <Route path="/donation/failed" element={<Failed />}></Route>
        <Route path="/transactions" element={<TransactionsPage />}></Route>
        <Route path="/debits" element={<Debits />}></Route>
        <Route path="/pages/edit/:pageId" element={<PageSettings />}></Route>
        <Route path="/pages" element={<Pages />}></Route>
        <Route path="/pages/add" element={<PagesAdd />}></Route>
        <Route path="/home" element={<Analytics />}></Route>
        <Route path="/debits/:mandateId" element={<EditDebit />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;