import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from './logoTechDogDark.png';
import donations from '../Api/Donations';

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const cookies = new Cookies();
  const navigate = useNavigate();

  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.000

  useEffect(() => {
    (async () => {
      const key = cookies.get('token');
      if (key) {
        try {
          await donations.get(`auth?ApiKey=${key}`);
          navigate('/home');
        } catch (error) {
          // console.clear();
          if (error.response.status !== 401) {
            console.warn(error.response.data);
          }
          cookies.remove('token', {path: '/'});
        }
      }
    })()
  }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await donations.get(`auth?email=${email}&password=${password}`);
      cookies.set('token', response.data.api_token, { expires: expirationDate });
      navigate('/home');
    } catch (error) {
      setError(error.response.data.error);
    }
  }
  
  return (
    <div className='m-5'>
      <div className='d-flex justify-content-center'>
        <form onSubmit={handleLogin} className='w-25'>
          <div className='mb-5'>
            <img src={logo} className="img-fluid" alt="techdoglogo"/>
          </div>
          <div className="mb-3 input-group-sm">
            <input type="email" required onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Gebruikersnaam..."/>
          </div>
          <div className="mb-3 input-group-sm">
            <input type="password" required onChange={e => setPassword(e.target.value)} className="form-control" placeholder="Wachtwoord..."/>
          </div>
          <div className='mb-3 input-group-sm'>
            <button type="submit" className="btn btn-primary w-100 rounded-pill">Log in</button>
          </div>
        </form>
      </div>
      {error}
    </div>
  );
}

export default Login;