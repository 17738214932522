import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';
import './Missing-info.css'
import donations from '../Api/Donations';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from "react-bootstrap";

const MissingInfo = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); // Parameters defined in the url
    const urlName = searchParams.get('name') || "";
    const urlIntervalType = searchParams.get('transactionType') || "";
    const urlIban = searchParams.get('iban') || ""; 

    const [paymentDetails, setPaymentDetails] = useState({'bankOwner': urlName, 'iban': urlIban}) // Payment details containing the name of the owner and the iban of the owner
    const [errorMessage, setErrorMessage] = useState("Vul alles correct in")
    const [submitting, setSubmitting] = useState(false); // For the loading circle when submitting
    const [missingParametersText, setMissingParametersText] = useState(""); // The text that tells the user which information we didn't receive.
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [loading, setLoading] = useState(true);

    //So we can correctly display and format the time using dayjs
    dayjs.extend(relativeTime);
    dayjs.locale('nl');

    //Get the mandate information
    useEffect(() => {
        (async () => {
            try {
                // Verify if the donation didn't contain a bank owner or a bank account
                const verifyResponse = await donations.get(`https://donations.techdogcloud.com/api/transactions/verify?orderId=${searchParams.get('transactionId')}`)
                if (verifyResponse.data.status !== 100) {
                    // if it did, return to the success page
                    window.location.href = window.location.origin + '/donation/success';
                }

                // To display what the user is missing using the missingParametersText variable
                if (paymentDetails.bankOwner === "" && paymentDetails.iban === "") { setMissingParametersText("IBAN en uw naam"); }
                else if (paymentDetails.bankOwner === "") { setMissingParametersText("naam"); }
                else if (paymentDetails.iban ==="") { setMissingParametersText("IBAN"); };

                // If any parameters are missing, return them to the donate page
                if (searchParams.get('name') == null || searchParams.get('iban') == null || searchParams.get('transactionId') == null || searchParams.get('transactionType') == null || searchParams.get('transactionInterval') == null || searchParams.get('amount') == null)  {
                    window.location.href = window.location.origin + '/doneer';
                }

                setLoading(false); // Render the page
            } catch (error) {
                console.warn(error);
                if (error.response.status === 401) {
                    cookies.remove('token', {path: '/'});
                    navigate('/');
                }
            }
        })()
    }, []);

    const submitInformation = async (event) => {
        setSubmitting(true) // Loading icon for the submit button
        event.preventDefault(); //Prevent the form from reloading the page

        // Check if the iban is correct and the bank account owner isn't empty
        if (validateIBAN(paymentDetails.iban) && paymentDetails.bankOwner !== "") {
            document.getElementById('submitError').style.display = "none";
            // Submit to pay via our api
            const incassoResponse = await donations.post(`https://donations.techdogcloud.com/api/directdebits/add-missing?&bankaccountOwner=${paymentDetails.bankOwner}&iban=${paymentDetails.iban}&orderId=${searchParams.get('transactionId')}&transactionType=${urlIntervalType}&transactionInterval=1`);
            const incassoData = incassoResponse.data;

            // Display the error based on the status code
            if (incassoData.status == 405) {
                setErrorMessage("Ongeldige IBAN");
                document.getElementById('submitError').style.display = "block";
            } else if (incassoData.status == 406) {
                setErrorMessage("OrderId bestaat niet");
                document.getElementById('submitError').style.display = "block";
            } else if (incassoData.status == 100) { // If it went succesfully, redirect the user to the success page
                window.location.href = window.location.origin + '/donation/success';
            }
            setSubmitting(false)
        } else {
            // If the iban is incorrect or the bank account owner is empty it will display the error
            document.getElementById('submitError').style.display = "block";
            setErrorMessage("Vul alles correct in");
            setSubmitting(false)
        }
    }

    function validateIBAN(ibanString) {
        // Test if first or second character isn't a letter
        if (!/^[a-zA-Z]{2}/.test(ibanString)) {
            return false;
        }
        
        // Test if third or fourth character isn't a number
        console.log(ibanString.substring(2, 4));
        if (!/^\d+$/.test(ibanString.substring(2, 4))) {
            return false;
        }
        
        // Test if fifth to eighth characters aren't letters
        if (!/^[a-zA-Z]{4}/.test(ibanString.substring(4, 8))) {
            return false;
        }
        
        // Test if ninth to eighteenth characters aren't numbers
        if (!/^\d+$/.test(ibanString.substring(8, 18))) {
            return false;
        }

        // Test if the iban isn't longer than 18 or shorter than 18
        if (ibanString.length < 18 || ibanString.length > 18) {
            return false;
        };

        // The iban is correct
        return true;
    }

    // On change for the input fields
    function onChange(event) {
        let error = false;
        if (event.target.id === "iban") {
            if (!validateIBAN(event.target.value)) {
                error = true;   
            }
        } else if (event.target.id === "bankOwner") {
            if (event.target.value == "") {
                error = true;
            }
        }

        // If the input contains an error input, it will display the error if the input doesn't surpass the validation
        const incassoErrorElement = event.target.parentElement.querySelector('.errorText');
        if (incassoErrorElement) {
            incassoErrorElement.style.display = error ? 'block' : 'none';
        }
        setPaymentDetails({...paymentDetails, [event.target.id]: event.target.value} )
    }

    // A switch case with all the frequencies bound to an int 
    function getFrequency(intervalType) {
        switch(intervalType) {
          case '1':
            return "Dagelijks";
          case '2':
            return "Wekelijks";
          case '3':
            return "Maandelijks";
          case '4':
            return "Elk Kwartaal";
          case '5':
            return "Elk Half jaar";
          case '6':
            return "Elk Jaar";
          default:
            return "Ongeldige invoer";
        }
    }

    return (
        <>
            {loading ?
            <div className='d-flex justify-content-center mt-4'>
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <div className='p-3 d-flex justify-content-center'>
                <div className='col-11 col-md-10 col-lg-8 col-xl-7 col-xxl-6'>
                    <h1 className='mb-4 text-center'>Missende informatie</h1>
                    <h5 className='text-center'>Er ging helaas iets mis, de betaling is gelukt maar door een fout hebben wij niet uw <strong>{missingParametersText}</strong> ontvangen.</h5>
                    <h5 className='mb-3 text-center extraInformation'>Zonder IBAN en/of naam kan er geen herhalende betaling plaatsvinden.</h5>
                    <Container className="col-6 d-flex justify-content-center">
                        <table className='table'>
                            <thead className=''>
                                <tr>
                                    <th>Herhaling</th>
                                    <th>Hoeveelheid</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getFrequency(urlIntervalType)}</td>
                                    <td>{parseFloat(searchParams.get('amount')).toLocaleString("nl-NL", { style: "currency", currency: "EUR" })}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Container>
                    <h2 className='text-center'>Invullen</h2>
                    <form onSubmit={submitInformation} className='d-flex justify-content-center align-items-center flex-column'>
                        <div className="form-floating mb-3 col-4">
                            <input onChange={onChange} value={paymentDetails.bankOwner} type="text" className="form-control mb-1" id="bankOwner" placeholder="John Doe"/>
                            <label htmlFor="bankOwner">Naam</label>
                            <span id='error' className='errorText'>Dit veld mag niet leeg zijn</span>
                        </div>
                        <div className="form-floating mb-3 col-4">
                            <input onChange={onChange} value={paymentDetails.iban} type="text" className="form-control mb-1" id="iban" placeholder="NL00BANK00000000"/>
                            <label htmlFor="iban">IBAN</label>
                            <span id='error' className='errorText'>Ongeldige IBAN</span>
                        </div>
                        {/* Edit buttons and Save/Cancel buttons */}
                        <div className='mb-4 d-flex flex-column align-items-center'>
                            <div className='d-flex gap-3 mb-2'>
                                <button type='submit' className='btn btn-success'>
                                {submitting ?
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : ''} Verzenden</button>
                            </div>
                            <span id='submitError' className='errorText'>{errorMessage}</span>
                        </div>
                    </form>
                </div>
            </div>}
        </>
    );
}

export default MissingInfo;