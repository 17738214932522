import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CountUp from 'react-countup';
import 'dayjs/locale/nl';
import donations from '../Api/Donations';
import { useTable } from "react-table";
import './Transactions.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import SideBar from '../SideBar/SideBar';
import TablePreset from "../Table/TablePreset";

const TransactionsPage = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);

    //So we can correctly display and format the time using dayjs
    dayjs.extend(relativeTime);
    dayjs.locale('nl');

    //Get the mandate information
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const transactionsResponse = await donations.get(`transactions/transactionreport?periodStart=2023-04-14&periodEnd=2023-05-14&excludeSandbox=0`); // Gets the order
    //             console.log(transactionsResponse.data);
    //             setTransactions(transactionsResponse.data);
    //             setLoading(false);
    //         } catch (error) {
    //             console.warn(error);
    //             if (error.response.status === 401) {
    //                 cookies.remove('token', {path: '/'});
    //                 navigate('/');
    //             }
    //         }
    //     })()
    // }, []);

    // Display the status based on the status code
    function paymentStatus(statusCode) {
        switch (statusCode) {
            case -90:
                return <span><FontAwesomeIcon className='statusIcon crossRed' icon={faXmark}/> Geannuleerd</span>
            case -63:
                return <span><FontAwesomeIcon className='statusIcon circleOrange' icon={faCircle}/> Geweigerd</span>
            case 85:
                return <span><FontAwesomeIcon className='statusIcon circleBlue' icon={faCheck}/> Verifiëren</span>
            case 20 || 25:
                return <span><FontAwesomeIcon className='statusIcon circleBlue' icon={faCircle}/> Afwachtend</span>
            case 95:
                return <span><FontAwesomeIcon className='statusIcon circleBlue' icon={faCheck}/> Geautoriseerd</span>
            case 50:
                return <span><FontAwesomeIcon className='statusIcon circleBlue' icon={faCircle}/> In betaalomgeving</span>
            case 100:
                return <span><FontAwesomeIcon className='statusIcon checkMarkGreen' icon={faCheck}/> Betaald</span>
            case -80:
                return <span><FontAwesomeIcon className='statusIcon checkMarkGray' icon={faCircle}/> Verlopen</span>
            default:
                return <span><FontAwesomeIcon className='statusIcon checkMarkGray' icon={faCheck}/> Onbekend</span>
        }
    }

    // Returns a table body with all the processed transactions
    function Transactions() {
        console.log(transactions);
        if (transactions.length > 0) {
            return (
                <tbody>
                    {transactions.slice().reverse().map((transaction, i) => (
                       <tr className='textNoWrap' key={i}>
                            <td>{transaction.payment_method ? transaction.payment_method : 'Onbekend'}<br/>{transaction.payment_category ? transaction.payment_category : 'Onbekend'}</td>
                            <td>{transaction.created_at ? transaction.created_at : 'Onbekend'}<br/>{dayjs(transaction.created_at).fromNow()}</td>
                            <td>{transaction.description ? transaction.description : 'Onbekend'}<br/>{transaction.transaction_id ? transaction.transaction_id : 'Onbekend'}</td>
                            <td>{transaction.bankaccount_owner ? transaction.bankaccount_owner : ''}<br/>{transaction.iban ? transaction.iban : ''}</td>
                            <td className='text-center'>{paymentStatus(transaction.status)}<br/>{transaction.process_time && transaction.status != -80 ? 'In ' + transaction.process_time + ' sec' : 'In - sec'}</td>
                            <td>€ {transaction.amount ? transaction.amount : 'Onbekend'}</td>
                       </tr>
                    ))}
                </tbody>
            );
        } else {
            return transactions.length;
        }
    }

    // Table preset
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
      "page_size": 10,
      "page": 1,
      "max_items": 0,
      "begin_of_page": 0,
      "end_of_page": 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
  
    const fetchAll = async () => {
      setLoading(true);
      const [response] = await Promise.all([
        donations.get(`transactions/transactionreport?periodStart=2023-04-14&periodEnd=2023-05-14&excludeSandbox=0&page=${pagination.page}&page_size=${pagination.page_size}${searchTerm ? `&searchTerm=${searchTerm}` : ''}${searchTerm ? `&noSearch=${[
          'id',
        ]}` : ''}`),
      ]);
  
      setData(response.data.data);
      setPagination({...pagination, "max_items": response.data.total, "begin_of_page": response.data.from, "end_of_page": response.data.to})
      setLoading(false);
    };
    console.log(data);
  
    useEffect(() => {
      setLoading(true);
      if (searchTerm === '') {
        fetchAll();
        return;
      }
      const waitFetch = setTimeout(() => {
        try {
          fetchAll();
        } catch (error) {
          console.warn(error)
        }
      }, 750);
  
      return () => clearTimeout(waitFetch);
    }, [searchTerm]);
  
    useEffect(() => {
      try {
        fetchAll();
      } catch (error) {
        console.warn(error)
      }
    }, [pagination.page_size, pagination.page]);
  
    const [selectedIds, setSelectedIds] = useState([]);
  
    // Handle checkbox selection
    const handleSelection = (productId, isSelected) => {
      setSelectedIds(previousIds => {
        if (isSelected) {
          return [...previousIds, productId];
        } else {
          return previousIds.filter(id => id !== productId);
        }
      });
    };
  
    // Columns for the table
    const columns = useMemo(
      () => [
        {
          Header: (
            <>
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  onChange={e => {
                    setSelectedIds(e.target.checked ? data.map(data => data.id) : []);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            const isSelected = selectedIds.includes(row.original.id);
            return (
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={e => handleSelection(row.original.id, e.target.checked)}
                />
                <span className="checkmark"></span>
              </label>
            );
          }
        },
        {
          Header: "Betaalmethode",
          Cell: ({ row }) => {
            return <span>{row.original.payment_method ? row.original.payment_method : 'Onbekend'}<br/>{row.original.payment_category ? row.original.payment_category : 'Onbekend'}</span> ;
          }
        },
        {
          Header: "Datum",
          Cell: ({ row }) => {
            return <span>{row.original.created_at ? row.original.created_at : 'Onbekend'}<br/>{dayjs(row.original.created_at).fromNow()}</span> ;
          }
        },
        {
          Header: "Omschrijving",
          Cell: ({ row }) => {
            return <span>{row.original.description ? row.original.description : 'Onbekend'}<br/>{row.original.transaction_id ? row.original.transaction_id : 'Onbekend'}</span>;
          }
        },
        {
        Header: "Betaalgegevens",
            Cell: ({ row }) => {
                return <span>{row.original.bankaccount_owner ? row.original.bankaccount_owner : ''}<br/>{row.original.iban ? row.original.iban : ''}</span>;
            }
        },
        {
        Header: "Status",
            Cell: ({ row }) => {
                return <span>{paymentStatus(row.original.status)}<br/>{row.original.process_time && row.original.status != -80 ? 'In ' + row.original.process_time + ' sec' : 'In - sec'}</span>;
            }
        },
        {
            Header: "Bedrag",
            Cell: ({ row }) => {
                return <span>€ {row.original.amount ? row.original.amount : 'Onbekend'}</span>;
            }
        },
      ],
      [selectedIds, setSelectedIds, data]
    );
  
    //   Table instance
    const tableInstance = useTable({ columns, data: data });
    
    const Action = (methode) => {
      if (methode == "suspend") {
      } else if (methode == 'Select All') {
        setSelectedIds(data.map(e => e.id));
      } else if (methode == 'De-select All') {
        setSelectedIds([]);
      }
    }
  
    return (
      <>
          {loading ?
          <div className='d-flex justify-content-center mt-4'>
              <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
          :
          <div className=''>
              <SideBar/>
              <div className='mainContainer'>
              {!loading ?
                  <div className='p-3 ps-0 tableSubContainer'>
                      <TablePreset data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]} setPagination={setPagination} handleSelection={handleSelection} primary_key={'id'}/>
                  </div>
                : <div className="table-container"><table><thead><tr><td><div>Loading</div></td></tr></thead></table></div>}
              </div>
          </div>}
      </>
    );
}

export default TransactionsPage;