import React from 'react';
import './SideBar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPager, faHouse, faReceipt, faCreditCard } from '@fortawesome/free-solid-svg-icons';

const SideBar = () => {
    return (
        <div className='sideBarContainer'>
            <h1 className='topIcon'>Donatie</h1>
            <a className='navbarIconTextContainer' href='/home'>
                <FontAwesomeIcon className='statusIcon sideBarIcon' icon={faHouse}/><span className='sideBarText'>Home</span>
            </a>
            <a className='navbarIconTextContainer' href='/pages'>
                <FontAwesomeIcon className='statusIcon sideBarIcon' icon={faPager}/><span className='sideBarText'>Pagina's</span>
            </a>
            <a className='navbarIconTextContainer' href='/transactions'>
                <FontAwesomeIcon className='statusIcon sideBarIcon' icon={faReceipt}/><span className='sideBarText'>Transacties</span>
            </a>
            <a className='navbarIconTextContainer' href='/debits'>
                <FontAwesomeIcon className='statusIcon sideBarIcon' icon={faCreditCard}/><span className='sideBarText'>Incasso's</span>
            </a>
        </div>
    );
}

export default SideBar;
