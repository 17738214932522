import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h1 className='m-3 text-center'>Helaas, de pagina die u probeert te bereiken bestaat niet.</h1>
            <h5 className='text-center'>Ga <a href='/'>terug</a></h5>
        </div>
    );
}

export default NotFound;
