import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import Cookies from 'universal-cookie';
import SideBar from '../SideBar/SideBar';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';
import { redirect, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import './Debits.css';
import { func } from 'prop-types';

const Debits = () => {
    const [loading, setLoading] = useState(true); // Loading the page
    const [debits, setDebits] = useState([]); // Processed debits
    const [activeDebits, setActiveDebits] = useState([]); // Active Debits
    const [incassoType, setIncassoType] = useState('processed'); // The current selected category you want to show
    const cookies = new Cookies();
    const navigate = useNavigate();

    // This is so we can correctly display time using dayjs
    dayjs.extend(relativeTime);
    dayjs.locale('nl');

    useEffect(() => {
        (async () => {
            try {
                const debitsResponse = await donations.get(`directdebits`); // Gets the incasso's from the api
                const activeDebitsResponse = await donations.get(`directdebits/active`); // Gets the active incasso's from the api

                setDebits(debitsResponse.data);
                setActiveDebits(activeDebitsResponse.data)

                setLoading(false);
            } catch (error) {
                console.warn(error);
                if (error.response.status === 401) {
                    cookies.remove('token', {path: '/'});
                    navigate('/');
                }
            }
        })()
    }, []);

    // Returns the frequence of the debit based on the intervalPeriod and the intervalValue
    function displayFrequency(intervalPeriod, intervalValue) {
        let frequencyString = '';
        switch (intervalPeriod) {
            case 1:
                frequencyString = intervalValue > 1 ? intervalValue + ' dagen' : 'dag';
                break;
            case 2:
                frequencyString = intervalValue > 1 ? intervalValue + ' weken' : 'week';
                break;
            case 3:
                frequencyString = intervalValue > 1 ? intervalValue + ' maanden' : 'maand';
                break;
            case 4:
                frequencyString = intervalValue > 1 ? intervalValue + ' kwartalen' : 'kwartaal';
                break;
            case 5:
                frequencyString = intervalValue > 1 ? intervalValue + ' half jaar' : 'half jaar';
                break;
            case 6:
                frequencyString = intervalValue > 1 ? intervalValue + ' jaar' : 'jaar';
                break;
            default:
                frequencyString = '-';
                break;
        }

        return frequencyString;
    }

    // Returns a table with all the processed debits
    function ProcessedIncassos() {
        if (debits.length > 0) {
            return (
                <tbody>
                    {debits.slice().reverse().map((incasso, i) => (
                       <tr className='textNoWrap' key={i}>
                            <td>{incasso.mandate_id ? incasso.mandate_id : ''}</td>
                            <td>{incasso.owner ? incasso.owner : ''}<br/>{incasso.iban ? incasso.iban : ''}</td>
                            <td>{incasso.description ? incasso.description : ''}</td>
                            <td>€ {incasso.amount ? incasso.amount : ''}</td>
                            <td>{incasso.created_at ? incasso.created_at : 'Onbekend'}<br/>{dayjs(incasso.created_at).fromNow()}</td>
                       </tr>
                    ))}
                </tbody>
            );
        } else {
            <tbody>
            </tbody>
        }
    }

    // Returns a table with all the active debits
    function ActiveIncassos() {
        if (activeDebits.length > 0) {
            return (
                <tbody>
                    {activeDebits.slice().reverse().map((incasso, i) => (
                       <tr className='textNoWrap' key={i} onClick={(e) => {navigate(`/debits/${incasso.mandate_id}`)}}>
                            <td>{incasso.mandate_id ? incasso.mandate_id : ''}</td>
                            <td>{incasso.owner ? incasso.owner : ''}<br/>{incasso.iban ? incasso.iban : ''}</td>
                            <td>{incasso.description ? incasso.description : ''}<br/>Elke {displayFrequency(incasso.interval_period, incasso.interval_value)}</td>
                            <td>€ {incasso.amount ? incasso.amount : ''}</td>
                            <td>{incasso.created_at ? incasso.created_at : 'Onbekend'}<br/>{dayjs(incasso.created_at).fromNow()}</td>
                            <td>{incasso.process_date ? incasso.process_date : 'Onbekend'}<br/>{dayjs(incasso.process_date).fromNow()}</td>
                       </tr>
                    ))}
                </tbody>
            );
        } else {
            <tbody>
            </tbody>
        }
    }

    // Set the value based on the category that's selected
    function switchIncasso(type) {
        setIncassoType(type);
    }

    return (
        <div className='d-flex position-absolute'>
            <SideBar/>
            <div className='mainContainer'>
                <div className='p-3 tableBox'>
                    <h1 className='d-flex flex-wrap pageTitle'>Uitgevoerde Incasso's</h1>
                    <div className='d-flex gap-2 p-2'>
                        <button id={incassoType == 'processed' ? 'debitSelected' : ''} className={'debitTypeButton'} onClick={(e) => switchIncasso('processed')}>Uitgevoerde Incasso's</button>
                        <button id={incassoType == 'active' ? 'debitSelected' : ''} className={'debitTypeButton'} onClick={(e) => switchIncasso('active')}>Lopende Incasso's</button>
                    </div>
                    {incassoType == 'processed' ?
                        <div className='bodyMaxVertical tableMinWidth'>
                            <table className='table table-responsive table-hover table-striped'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Mandaat Id</th>
                                        <th>Betaalgegevens</th>
                                        <th>Omschrijving</th>
                                        <th>Bedrag</th>
                                        <th>Verwerkt Op</th>
                                    </tr>
                                </thead>
                                <ProcessedIncassos/>
                            </table>
                        </div>
                        :
                        ''
                    }
                    {incassoType == 'active' ?
                        <div className='bodyMaxVertical tableMinWidth'>
                            <table className='table table-responsive table-hover table-striped'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Mandaat Id</th>
                                        <th>Betaalgegevens</th>
                                        <th>Omschrijving</th>
                                        <th>Bedrag</th>
                                        <th>Aangemaakt</th>
                                        <th>Volgende Afschrijving</th>
                                    </tr>
                                </thead>
                                <ActiveIncassos/>
                            </table>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    );
}

export default Debits;
