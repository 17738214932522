import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Donation.css';
import Cookies from 'universal-cookie';
import donations from '../Api/Donations';
import { func } from 'prop-types';
import { async } from 'q';
const images = require.context('../../images', true);

const DonationPage = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const customAmountRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState('one-time'); //The button that is selected. Options: one-time, monthly, quarterly, yearly, custom
  //The values that get set by the api call to get the latest settings
  const [settings, setSettings] = useState({
    amount_color: "",
    donate_color: "",
    donation_description: "",
    donation_success_msg: "",
    donation_title: "",
    fee: 0,
    selection_color: "",
  });

  //Colors for the buttons
  const [styles, setStyles] = useState({
    typeColor: '#000000',
    selectionColor: '#000000',
    donateButtonColor: '#000000',
  })

  const [donationAmount, setDonationAmount] = useState(5); //The amount that the user is gonna pay
  const [paymentMethod, setPaymentMethod] = useState(null); //Method: IDEAL, IBAN. When null pay makes sure the user makes a choice on their end
  const [customAmountSelected, setCustomAmountSelected] = useState(false); //If the user has selected a custom amount or not
  const [loading, setLoading] = useState(true); //If the site is loading, gets set to false once the api calls are finished
  const [donating, setDonating] = useState(false); //When the button to donate has been pressed, gives a small visual rotating circle for the donation button
  const [customAmount, setCustomAmount] = useState(0); //The amount the user is gonna pay when it has input a custom value
  const { pageId } = useParams();

  //An array of all set values for every category. Example: When monthly is selected, it will display 5, 10 and 25 euro buttons along side the custom one.
  const donationAmounts = {
    'one-time': [5, 10, 25, 50, 100],
    'monthly': [5, 10, 25],
    'quarterly': [5, 10, 25, 50],
    'yearly': [5, 10, 25, 50, 100],
  }

  //These are the ids that are connected to the the database. 0 = niet herhalend. 1 = Dag. 2 = Week. 3 = Maand. 4 = Kwartaal. 5 = Half jaar. 6 = Jaar
  const donationIds = {
    'one-time': 0,
    'monthly': 3,
    'quarterly': 4,
    'yearly': 6,
  }

  //Function that converts hex code to rgb
  function hexToRgb(hex) {
    // Convert the hex color string to a number
    const hexNum = parseInt(hex.replace(/^#/, ''), 16);
    
    // Extract the red, green, and blue components from the hex number
    const red = (hexNum >> 16) & 255;
    const green = (hexNum >> 8) & 255;
    const blue = hexNum & 255;
    
    // Return the RGB color string
    return `${red}, ${green}, ${blue}`;
  }

  //First time the application runs
  useEffect(() => {
    (async () => {
      try {
        const settingsResult = await donations.get(`page/info?id=${pageId}`); //Get the settings from the database
        const settingsData = settingsResult.data;
        setSettings(settingsResult.data);
        //Set the styles
        setStyles({
          typeColor: settingsData.type_color,
          selectionColor: settingsData.selection_color,
          donateButtonColor: settingsData.donate_color,
        })

        //Change the styling of the donate button to the color of the setting
        const donateButton = document.querySelector('.donateButton');
        donateButton.style.setProperty('--custom-color', hexToRgb(settingsData.donate_color));

        setLoading(false);
      } catch (error) {
        console.warn(error);
        cookies.remove('token');
        navigate('/');
      }
    })()
  }, []);

  //When custom amount gets selected this makes sure the user gets directed to the amount input so it can start typing right away
  useEffect(() => {
    if (customAmountSelected) {
      customAmountRef.current.focus();
    }
  }, [customAmountSelected]);
  
  // When the user presses a button with a value
  function handleDonationOnClick(amount) {
    setDonationAmount(amount);
    setCustomAmountSelected(false);
  }

  //When the user presses the custom amount button
  function handleCustomOnClick() {
    setCustomAmountSelected(true);
    customAmountRef.current.focus();
  }

  //When the user inputs a value in the custom amount input
  function handleCustomOnChange(event) {
    setCustomAmount(event.target.value);
  }

  //When the user presses the donate button
  const startDonation = async () => {
    if (!donating) {
      setDonating(true); //Adds the loading spinning thingy to the donation button
      //Calculates the amount depending on if the user has inputted a custom amount or has selected a button. Also adds the fee if there is one
      const amount = (customAmountSelected ? parseFloat(customAmount) : donationAmount) + parseFloat(settings.fee !== null ? settings.fee : 0);
      //Sets the payment method if it has been selected
      let paymentMethodUrl = paymentMethod !== null ? `paymentMethodID=${paymentMethod}` : '';

      //Post the donation to the api 
      const donationResponse = await donations.post(`transactions/start?${paymentMethodUrl}&amount=${amount}&description=${settings.donation_description}&transactionType=${donationIds[selectedButton]}&pageId=${pageId}&testmode=1`);
      const paymentUrl = donationResponse.data;

      //Redirects the user to the url that is returned from the api
      window.location.replace(paymentUrl);
    }
  }

  console.log(selectedButton);

  return (
    <div className='mt-2 d-flex justify-content-center'>
      <div className='col-11 col-md-10 col-lg-8 col-xl-7 col-xxl-6'>
        <h1 className='donateTitle'>{settings.donation_title}</h1>
        {/* Donatie Type */}
        <div className='transactionTypeContainer d-flex flex-grow-1 flex-wrap mb-2'>
          <div style={selectedButton === 'one-time' ? {boxShadow: `0 3px 0 ${styles.typeColor}`} : {}} onClick={(e) => setSelectedButton('one-time')} className='col-sm-3 col-12 unselectable transactionTypeButton'>
            <span style={selectedButton === 'one-time' ? {color: styles.typeColor} : {}}>Eenmalig</span>
          </div>
          <div style={selectedButton === 'monthly' ? {boxShadow: `0 3px 0 ${styles.typeColor}`} : {}} onClick={(e) => setSelectedButton('monthly')} className='col-sm-3 col-12 unselectable transactionTypeButton'>
            <span style={selectedButton === 'monthly' ? {color: styles.typeColor} : {}}>Maandelijks</span>
          </div>
          <div style={selectedButton === 'quarterly' ? {boxShadow: `0 3px 0 ${styles.typeColor}`} : {}} onClick={(e) => setSelectedButton('quarterly')} className='col-sm-3 col-12 unselectable transactionTypeButton'>
            <span style={selectedButton === 'quarterly' ? {color: styles.typeColor} : {}}>Elk kwartaal</span>
          </div>
          <div style={selectedButton === 'yearly' ? {boxShadow: `0 3px 0 ${styles.typeColor}`} : {}} onClick={(e) => setSelectedButton('yearly')} className='col-sm-3 col-12 unselectable transactionTypeButton'>
            <span style={selectedButton === 'yearly' ? {color: styles.typeColor} : {}}>Jaarlijks</span>
          </div>
        </div>
        {/* Donatie waarde */}
        <div className='d-flex flex-wrap justify-content-center mb-2'>
          {donationAmounts[selectedButton].map((amount) => (
            <button 
            key={amount}
            className='unselectable transactionAmountButton'
            style={amount === donationAmount && customAmountSelected === false ? {boxShadow: `0 0 0 2px ${styles.selectionColor}`} : {}}
            onClick={(e) => handleDonationOnClick(amount) }
            >
              € {amount},00
            </button>
          ))}
          <div
          style={customAmountSelected ? { display: "flex", alignItems: "center", boxShadow: `0 0 0 2px ${styles.selectionColor}`} : {display: "flex", alignItems: "center"}}
          className='unselectable transactionAmountButton' 
          id={customAmountSelected ? 'selected' : ''}
          onClick={handleCustomOnClick}
          >
            <span style={{'position': 'absolute', 'visibility': customAmountSelected ? 'visible' : 'hidden'}}>€</span>
            <input
              className='customAmountInputField p-0'
              placeholder='Anders'
              onChange={handleCustomOnChange}
              value={customAmountSelected ? customAmount || '' : ''}
              disabled={!customAmountSelected}
              ref={customAmountRef}
              type="number"
              style={{ textAlign: 'align' }}
            />
          </div>
        </div>
        <h1 className='donateMethodTitle'>Hoe wil je betalen?</h1>
        <div className='d-flex flex-wrap justify-content-center'>
          <button className='transactionMethodButton' style={paymentMethod === 10 ? {boxShadow: `0 0 0 2px ${styles.selectionColor}`} : {}} onClick={(e) => setPaymentMethod(10)}><img alt='IDEAL' draggable={false} src={images('./ideal-logo.png')}/></button>
          <button className='transactionMethodButton' style={paymentMethod === 436 ? {boxShadow: `0 0 0 2px ${styles.selectionColor}`} : {}} onClick={(e) => setPaymentMethod(436)}><img alt='Bancontact' draggable={false} src={images('./bancontact-logo.png')}/></button>
        </div>
        <div className='d-flex flex-column justify-content-center align-content-center flex-wrap'>
          <button className='donateButton' style={{backgroundColor: styles.donateButtonColor}} onClick={startDonation}>
            { donating ?
                <div className='d-flex justify-content-center'>
                    <div className="spinner-border text-black" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
              :
              'Doneer'
            }
          </button>
          {loading ? 
            ''
          :
            <p className='disclaimer text-center'>{settings.fee !== null ? '(Transactie kosten: € ' + settings.fee + ')' : ''}</p>
          }
        </div>
      </div>
    </div>
  );
}

export default DonationPage;