import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import Cookies from 'universal-cookie';
import './Settings.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../SideBar/SideBar';

const PageSettings = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const { pageId } = useParams();

    // The settings information gathered from the API call
    const [settings, setSettings] = useState({
        page_title: "",
        donation_title: "",
        donation_description: "",
        donation_success_msg: "",
        type_color: "#ffffff",
        selection_color: "#ffffff",
        donate_color: "#ffffff",
        fee: 0.00,
    });

    // API call when the page first loads
    useEffect(() => {
        (async () => {
            try {
                const settingsResponse = await donations.get(`page/info?id=${pageId}`); // API
                console.log(settingsResponse.data);
                setSettings(settingsResponse.data);
                setLoading(false);
            } catch (error) {
                console.warn(error);
                if (error.response.status === 401) {
                    cookies.remove('token', {path: '/'});
                    navigate('/');
                }
            }
        })()
    }, []);

    // Input change of the setting inputs
    const handleInputChange = (event, key) => {
        setSettings({...settings, [key]: event.target.value})
    }

    // Save the changes
    const submitChanges = async () => {
        setSubmitting(true);
        try {
            const pageUpdateRepsonse = await donations.put(`pages?page_id=${pageId}`, settings); // Update the settings with the API
            console.log(pageUpdateRepsonse.data);
            // When the status isn't 100 notify the user that something went wrong
            if (pageUpdateRepsonse.data.status != 100) {
                toast.error('Er ging iets fout, probeer het later nog eens', { //Toast to notify the user
                    position: "top-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            } else {
                toast.success('Instellingen opgeslagen!', { //Toast to notify the user
                    position: "top-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            };
        } catch (error) {
            console.warn(error);
            if (error.response.status === 401) {
                cookies.remove('token', {path: '/'});
                navigate('/');
            }
        }
        setSubmitting(false);
    }

    return (
        <div className='d-flex position-absolute'>
            <SideBar/>
            <div className='mainContainer'>
                <div className='p-3 col-sm-10 col-lg-7 col-xl-6 col-md-9 col-12'>
                <h1>Instellingen</h1>
                {loading ? 
                <div className='d-flex justify-content-center mt-4 loading-icon'>
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <>
                    <div className='settingGroup'>
                        <h3>Pagina</h3>
                        <div className='inputContainer'>
                            <span id='name'>Pagina Titel </span><input type='text' value={settings.page_title} onChange={(e) => handleInputChange(e, "page_title")}/>
                        </div>
                    </div>
                    <div className='settingGroup'>
                        <h3>Donatie pagina</h3>
                        <div className='inputContainer'>
                            <span id='name'>Titel </span><input type='text' value={settings.donation_title} onChange={(e) => handleInputChange(e, "donation_title")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Transactie kosten </span><input value={settings.fee} type='number' onChange={(e) => handleInputChange(e, "fee")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Balk selecteer kleur </span><input type='color' value={settings.type_color} onChange={(e) => handleInputChange(e, "type_color")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Bedrag kleur </span><input type='color' value={settings.selection_color} onChange={(e) => handleInputChange(e, "selection_color")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Doneer knop kleur  </span><input type='color' value={settings.donate_color} onChange={(e) => handleInputChange(e, "donate_color")}/>
                        </div>
                        <br/>
                    </div>

                    <div className='settingGroup'>
                        <h3>Overig</h3>
                        <div className='inputContainer'>
                            <span id='name'>Donatie beschrijving </span><input type='text' value={settings.donation_description} onChange={(e) => handleInputChange(e, "donation_description")}/>
                        </div>
                        <div className='inputContainer'>
                            <span id='name'>Betaling Geslaagd Tekst </span><input type='text' value={settings.donation_success_msg} onChange={(e) => handleInputChange(e, "donation_success_msg")}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button className='save-button' onClick={submitChanges}> {submitting ?
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : ''} Sla op</button>
                        </div>
                    </div>

                    <button className='back-button' onClick={(e) => navigate(`/pages`)}>Terug</button>
                </>}
                </div>
                <ToastContainer/>
            </div>
        </div>
    );
}

export default PageSettings;