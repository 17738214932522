import React, { useEffect, useState } from 'react';
import donations from '../Api/Donations';
import Cookies from 'universal-cookie';
import './Pages.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../SideBar/SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

const Pages = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    // The pages from the api
    const [pages, setPages] = useState([]);

    // API call when the page first loads
    useEffect(() => {
        (async () => {
            try {
                const response = await donations.get(`pages`); // API
                setPages(response.data);
                setLoading(false);
            } catch (error) {
                console.warn(error);
                if (error.response.status === 401) {
                    cookies.remove('token', {path: '/'});
                    navigate('/');
                }
            }
        })()
    }, []);
    
    const pageAddRedirect = () => {
        
    }

    return (
        <div className='d-flex position-absolute'>
            <SideBar/>
            <div className='mainContainer'>
                <div className='p-3 col-12'>
                <h1>Pagina's</h1>
                {loading ? 
                <div className='d-flex justify-content-center mt-4 loading-icon'>
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <>
                    <div className='pages-container'>
                        {pages.map((page, index) => (
                        <div key={index} className='page-display' onClick={(e) => navigate(`/pages/edit/${page.page_id}`)}>
                            <FontAwesomeIcon className='edit-page-icon' icon={faPenToSquare}/>
                            <span className='page-display-title'>{page.page_title}</span>
                        </div>
                        ))}
                        <div key={Math.max(...pages.map(item => item.page_id))} className='page-display' onClick={(e) => navigate('/pages/add')}>
                            <FontAwesomeIcon className='new-page-icon' icon={faCirclePlus}/>
                        </div>
                    </div>
                </>}
                </div>
                <ToastContainer/>
            </div>
        </div>
    );
}

export default Pages;
